.movies-card {
  position: relative;
  width: 362px;
  cursor: pointer;
}

.movies-card__image {
  width: 362px;
  height: 200px;
  border-radius: 7px;
  object-fit: cover;
}

.movies-card__button {
  position: absolute;
  top: 14px;
  right: 14px;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
  border: none;
}

.movies-card__button::before {
  content: " ";
}

.movies-card__button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.movies-card__button_save {
  width: 72px;
  height: 21px;
  border-radius: 30px;
  background: #f9f9f9;
}

.movies-card__button_saved {
  background-position: center;
  background-image: url(../../images/saved.svg);
  width: 21px;
  height: 21px;
  background-color: rgba(0, 0, 0, 0);
}

.movies-card__button_delete {
  background-image: url(../../images/deleteButton.svg);
  width: 21px;
  height: 21px;
  background-position: center;
  background-color: rgba(0, 0, 0, 0);
}

.movies-card__info {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 14px;
}

.movies-card__title {
  margin: 0 0 0 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.movies-card__container-duration {
  width: 52px;
  height: 18px;
  border-radius: 3px;
  background: #f9f9f9;
  margin: 5px 18px 0 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.movies-card__duration {
  margin: 0;
  color: #a0a0a0;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
}

@media screen and (max-width: 1023px) {
  .movies-card__image {
    width: 340px;
    height: 190px;
  }

  .movies-card {
    width: 340px;
  }

  .movies-card__info {
    margin-top: 20px;
  }

  .movies-card__title {
    margin-left: 0;
    font-size: 13px;
  }

  .movies-card__container-duration {
    margin: 5px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .movies-card__image {
    width: 300px;
    height: 170px;
  }

  .movies-card {
    width: 300px;
  }

  .movies-card__title {
    font-size: 12px;
  }

  .movies-card__info {
    margin-top: 5px;
  }
}
