.auth {
  margin: 0 auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.auth__content {
  flex: 1;
  margin-top: 70px;
  width: 396px;
}

.auth__title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 40px 0 35px;
}

.auth__label {
  color: #a0a0a0;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0 10px;
}

.auth__input {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 46px;
  width: 96%;
  border: none;
  border-radius: 8px;
  background: #f9f9f9;
  padding-left: 15px;
}

.auth__input_email {
  font-size: 13px;
  font-weight: 500;
}

.auth__button {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  background: #000;
  color: white;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}

.auth__button_error {
  background: #F8F8F8;
  color: #C2C2C2;
}

.auth__button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.auth__signup {
  color: #a0a0a0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 16px 0 0;
}

.auth__link {
  color: #000;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.auth__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

.auth__content {
  flex: 1;
}

.auth__button-container {
  flex-shrink: 0;
  margin-bottom: 70px;
  width: 396px;
}

.auth__error {
  color: #FF4062;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: none;
}

.auth__error_active {
  display: block;
}

@media screen and (max-width: 1023px) {
  .auth__content {
    margin-top: 232px;
  }

  .auth__button-container {
    margin-bottom: 232px;
  }
}

@media screen and (max-width: 767px) {
  .auth {
    align-items: center;
  }

  .auth__form {
    width: 100%;
  }

  .auth__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
    width: 260px;
  }

  .auth__button-container {
    margin-bottom: 30px;
    width: 260px;
  }

  .auth__title {
    text-align: center;
    margin: 50px 0 57px;
  }

  .auth__signup {
    font-size: 12px;
  }

  .auth__link {
    font-size: 12px;
  }
}
