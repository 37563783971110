.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.not-found__header {
  margin: 246px 0 0;
  text-align: center;
  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.not-found__text {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0 0;
}

.not-found__link {
  margin: 184px 0 60px;
  color: #000;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.not-found__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .not-found__header {
    margin-top: 408px;
  }

  .not-found__link {
    margin-bottom: 222px;
  }
}

@media screen and (max-width: 767px) {
  .not-found__header {
    margin-top: 329px;
    font-size: 80px;
  }

  .not-found__text {
    margin-top: 10px;
    font-size: 12px;
  }

  .not-found__link {
    margin: 284px 0 30px;
    font-size: 12px;
  }
}
