.about-me {
  display: flex;
  justify-content: center;
}

.about-me__container {
  width: 87.5%;
  max-width: 1140px;
}

.about-me__header {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
  margin: 110px 0 23px 0;
  padding: 0 0 20px;
  border-bottom: solid black 1px;
}

.about-me__two-columns {
  display: flex;
  justify-content: space-between;
  margin: 66px 0 95px;
}

.about-me__subheader {
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -2px;
  margin: 0;
  padding: 0;
}

.about-me__description {
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 18px 0 0;
  padding: 0;
  max-width: 600px;
}

.about-me__text {
  width: 94%;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 26px 0 99px;
  padding: 0;
  max-width: 600px;
}

.about-me__link {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  margin: 0;
}

.about-me__img {
  width: 270px;
  height: 327px;
  border-radius: 10px;
}

@media screen and (max-width: 1023px) {
  .about-me__header {
    margin-top: 90px;
  }

  .about-me__subheader {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -1.6px;
  }

  .about-me__description {
    font-size: 12px;
    line-height: 18px;
  }

  .about-me__text {
    font-size: 12px;
    line-height: 18px;
    margin: 20px 0 87px;
  }

  .about-me__img {
    width: 255px;
    height: 307px;
  }

  .about-me__two-columns {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) {
  .about-me__header {
    font-size: 18px;
    letter-spacing: -0.72px;
    margin: 50px 0 28px;
    padding-bottom: 30px;
  }

  .about-me__two-columns {
    flex-direction: column-reverse;
    margin: 60px 0 70px;
  }

  .about-me__img {
    width: 292px;
    height: 352px;
  }

  .about-me__container {
    width: 92%;
  }

  .about-me__subheader {
    margin-top: 35px;
    font-size: 30px;
    letter-spacing: -1.2px;
  }

  .about-me__description {
    font-size: 11px;
    line-height: 16px;
  }

  .about-me__text {
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 40px;
  }

  .about-me__column:last-child {
    display: flex;
    justify-content: center;
  }
}
