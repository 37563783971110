.movies {
  display: flex;
  flex-direction: column;
}

.movies__button {
  margin: 80px auto 80px;
  width: 320px;
  height: 36px;
  border-radius: 6px;
  background: #f9f9f9;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 0;
}

.movies__button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.movies__not-found {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 25px auto 0;
}

@media screen and (max-width: 1023px) {
  .movies__button {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  .movies__button {
    width: 240px;
  }

  .movies__not-found {
    font-size: 12px;
  }
  
}
