.portfolio {
  display: flex;
  justify-content: center;
}

.portfolio__container {
  width: 87.5%;
  margin-bottom: 105px;
  max-width: 1140px;
}

.portfolio__header {
  color: #a0a0a0;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 30px;
  padding: 0;
}

.portfolio__link {
  min-height: 90px;
  text-decoration: none;
  border-bottom: #e8e8e8 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portfolio__link:last-child {
  border-bottom: none;
}

.portfolio__link-text {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: -1.2px;
  margin: 0;
}

.portfolio__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

.portfolio__link-arrow {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -1.2px;
  margin: 0;
  color: black;
}

@media screen and (max-width: 1023px) {
  .portfolio__link-text {
    font-size: 28px;
    line-height: 50px;
    letter-spacing: -1.12px;
  }

  .portfolio__container {
    margin-bottom: 67px;
  }

  .portfolio__link-arrow {
    font-size: 28px;
    letter-spacing: -1.12px;
  }
}

@media screen and (max-width: 767px) {
  .portfolio__container {
    width: 92%;
    margin-bottom: 50px;
  }

  .portfolio__header {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .portfolio__link {
    min-height: 68px;
  }

  .portfolio__link-text {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }

  .portfolio__link-arrow {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }
}
