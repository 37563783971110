.navtab {
  height: 66px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navtab__link {
  margin: 0 40px 0 0;
  padding: 0;
  color: white;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;
  text-underline-position: under;
}

.navtab__link:hover {
  opacity: 0.7;
}

.navtab__link:last-child {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .navtab__link {
    margin-right: 24px;
    font-size: 11px;
    line-height: 16px;
  }
}
