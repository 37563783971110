.techs {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}

.techs__container {
  width: 87.5%;
  max-width: 1140px;
}

.techs__header {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;
  margin: 100px 0 23px 0;
  padding: 0 0 20px;
  border-bottom: solid black 1px;
}

.techs__subheader {
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -2px;
  margin: 90px 0 0;
  padding: 0;
}

.techs__text {
  max-width: 460px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.56px;
  margin: 26px auto 0;
}

.techs__list {
  margin: 100px auto 100px;
  display: flex;
  justify-content: center;
}

.techs__tech-container {
  width: 90px;
  height: 60px;
  border-radius: 10px;
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.techs__tech-container:last-child {
  margin-right: 0;
}

.techs__tech-name {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1023px) {
  .techs__header {
    margin-top: 90px;
  }

  .techs__subheader {
    margin-top: 80px;
  }

  .techs__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.48px;
  }

  .techs__list {
    margin: 83px auto 90px;
  }

  .techs__tech-container {
    width: 84px;
    height: 57px;
  }

  .techs__tech-name {
    font-size: 12px;
    letter-spacing: -0.48px;
  }
}

@media screen and (max-width: 767px) {
  .techs {
    background-color: white;
  }

  .techs__header {
    font-size: 18px;
    letter-spacing: -0.72px;
    margin: 45px 0 28px;
    padding-bottom: 30px;
  }

  .techs__subheader {
    margin-top: 50px;
    font-size: 30px;
    letter-spacing: -1.2px;
  }

  .techs__text {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.44px;
    margin-top: 10px;
  }

  .techs__list {
    flex-wrap: wrap;
    width: 190px;
    margin-top: 40px;
    justify-content: start;
  }

  .techs__tech-container {
    margin: 10px 10px 0 0px;
  }
}
