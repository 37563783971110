.search {
  border-bottom: #e8e8e8 1px solid;
  width: 92%;
  max-width: 1140px;
  margin: 0 auto 0;
}

.search__form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 70px auto 80px;
  max-width: 1140px;
  height: 72px;
  box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.08);
  border-radius: 9px;
}

.search__form::before {
  content: " ";
  background: url(../../images/findIcon.svg);
  width: 34px;
  height: 34px;
  margin-left: 19px;
}

.search__finder {
  display: flex;
  justify-content: start;
  width: 80%;
}

.search__input {
  border: none;
  width: 100%;
  padding: 0;
  color: #a0a0a0;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0 25px 0 10px;
}

.search__shorties {
  max-width: 20%;
  min-width: max-content;
  border-left: solid 1px #cbcbcb;
  height: 40px;
  display: flex;
  align-items: center;
  padding-right: 30px;
}

.search__button {
  min-width: 60px;
  height: 34px;
  border-radius: 48px;
  border: none;
  background: #000;
  color: white;
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-right: 19px;
}

.search__button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.search__tumb {
  margin: 0;
}

.search__checkbox-label {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.search__tumb {
  width: 1px; /* если у элемента не будет размеров, некоторые браузеры посчитают, что его вообще нет */
  height: 1px;
  overflow: hidden; /* без этого свойства тоже сработает, но так наверняка. Мы его ещё изучим, оно скрывает элементы, выходящие за границы родителя */
  clip-path: inset(0 0 0 0);
}

.search__tumb-visible {
  background-image: url(../../images/smalltumb_off.svg);
  width: 36px;
  height: 20px;
  display: inline-block;
  border: none;
  background-position: center;
  margin-right: 15px;
  margin-left: 28px;
  background-repeat: no-repeat;
}

.search__tumb-visible:hover {
  cursor: pointer;
  opacity: 0.8;
}

.search__tumb:checked + .search__tumb-visible {
  background-image: url(../../images/smalltumb_active.svg);
}

.search__error {
  color: #FF4062;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 1023px) {
  .search__form {
    margin-bottom: 110px;
  }

  .search__shorties {
    padding-right: 15px;
  }

  .search__button {
    margin-right: 20px;
  }

  .search .search__tumb-visible {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .search {
    position: relative;
    border-bottom: none;
  }

  .search__form {
    flex-direction: column;
    padding-top: 5px;
  }

  .search__input {
    margin: 0;
    font-size: 14px;
  }

  .search__button {
    margin: 0;
    padding: 0;
  }

  .search__form::before {
    display: none;
  }

  .search__shorties {
    padding-right: 0px;
    position: relative;
    top: 110px;
    left: 0%;
    min-width: 100%;
    border-left: none;
    border-bottom: solid 1px #cbcbcb;
    justify-content: center;
    margin-top: -30px;
  }

  .search__finder {
    align-items: center;
    width: 90%;
    margin-top: 15px;
  }

  .search__checkbox-label {
    margin-bottom: 70px;
    font-size: 11px;
  }

  .search__tumb-visible {
    margin: 0 13px 0 0;
  }
}
